import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Advance Traffic Engineering`}</h1>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/05/ATE-Lecture-3.pdf"
      }}>{`ATE-Lecture 3`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/05/ATE-Lecture-4.pdf"
      }}>{`ATE-Lecture 4`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/05/ATE-Lecture-5.pdf"
      }}>{`ATE-Lecture 5`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://deprecated.seismicconsolidation.com/wp-content/uploads/2020/05/ATE-Lecture-6.pdf"
      }}>{`ATE-Lecture 6`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      